@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
body {
    font-family: "Poppins", sans-serif;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 12px;
    margin-bottom: 10px;
    /* box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1); */
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #0ba3ba;
    margin: 40px 0;
}

i,
span {
    display: inline-block;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    align-items: stretch;
}

#sidebar {
    min-width: 200px;
    max-width: 200px;
    background: #fafbfc;
    color: #989fa7;
    transition: all 0.3s;
    border-right: 1px solid #e5e5e5;
    font-weight: 400;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

#sidebar.active {
    padding-top: 20px;
    min-width: 50px;
    max-width: 120px;
    text-align: center;
}

#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
    display: none;
}

#sidebar.active .sidebar-header strong {
    display: block;
}

#sidebar ul li a {
    text-align: left;
}

#sidebar ul li.pay a {
    color: white;
    background-color: rgb(81, 223, 129);
}

#sidebar.active ul li a {
    padding: 8px 5px;
    text-align: center;
    font-size: 0.6em;
}

#sidebar.active ul li a i {
    margin-right: 0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
}

#sidebar.active ul ul a {
    padding: 10px !important;
}

#sidebar.active .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    transform: translateX(50%);
}

#sidebar .sidebar-logo {
    padding: 10px 65px;
}

#sidebar .sidebar-header {
    height: 130px;
}

#sidebar .sidebar-header img {
    height: 120px;
}

#sidebar.active .sidebar-header {
    display: none;
}

#sidebar .sidebar-header strong {
    display: none;
    font-size: 1.8em;
}

#sidebar ul.components {
    padding: 0px;
    /* border-bottom: 1px solid #e5e5e5; */
}

#sidebar ul li a {
    padding: 11px;
    font-size: 0.9em;
    display: block;
    /* border-bottom: 1px solid; */
}

#sidebar ul li a:hover {
    color: #0062cc;
    /* background: #1f2632; */
}

#sidebar ul li a i {
    margin-right: 10px;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
    color: #293241;
}

#sidebar a.active {
    color: #0062cc;
    font-weight: 500;
}

#sidebar a.active i {
    color: #293241;
}

a[data-toggle="collapse"] {
    position: relative;
}

#sidebar .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    /*background: #000;*/
    background: #6d7fcc;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

#sidebar ul li a.download {
    padding: 6px;
}

a.download {
    background: #fff;
    color: #0062cc;
}

a.article,
a.article:hover {
    background: #0062cc !important;
    color: #fff !important;
}

#sidebar.active .sidebar-logo {
    display: none;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    padding-left: 10px;
    min-height: 100vh;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        margin-left: -80px !important;
        font-size: small;
    }


    #sidebar.active {
        min-width: 180px;
    }



    #sidebar .sidebar-logo {
        padding: 8px 50px;
    }



    #sidebar .dropdown-toggle::after {
        top: auto;
        bottom: 10px;
        right: 50%;
        transform: translateX(50%);
    }

    #sidebar.active {
        margin-left: 0 !important;
    }

    #sidebar .sidebar-header h3,
    #sidebar .CTAs {
        display: none;
    }

    #sidebar .sidebar-header {
        height: auto;
    }

    #sidebar .sidebar-header strong {
        padding: 20px 0px;
        display: none;
    }

    #sidebar ul li a {
        padding: 20px 10px;
    }

    #sidebar ul li a span {
        font-size: 0.7em;
    }

    #sidebar.active ul li a span {
        font-size: 1em;
    }


    #sidebar ul li a i {
        margin-right: 0;
        display: block;
    }

    #sidebar ul ul a {
        padding: 10px !important;
    }

    #sidebar ul li a i {
        font-size: 1.3em;
    }

    #sidebar.active ul li a i {
        font-size: 3em;
    }

    #sidebar {
        margin-left: 0;
    }

    #sidebarCollapse span {
        display: none;
    }

    body {
        margin-top: 70px;
    }
}

.react-datepicker-wrapper {
  display: inline !important;
}

/* #calendar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  top: 64px;
} */

/* lds ellipsis */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 10px;
}
.lds-ellipsis div {
  position: absolute;
  top: 0px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(255, 253, 253);
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* lds roller */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: rgb(192, 192, 192);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@-webkit-keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* LDS Facebook */
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: dodgerblue;
  -webkit-animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
          animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  -webkit-animation-delay: -0.24s;
          animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  -webkit-animation-delay: -0.12s;
          animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  -webkit-animation-delay: 0;
          animation-delay: 0;
}
@-webkit-keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
section.pricing {
  background: #007bff;
  background: linear-gradient(to right, #0062e6, #33aeff);
}

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
}

.faq p {
  color: grey;
  padding-right: 300px;
}

.faq {
  display: block;
  padding-bottom: 200px;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }
  .pricing .card:hover .btn {
    opacity: 1;
  }
}

/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-bottom: 5px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

html, body, #root, .container {
    height: 100%;
}
.card-header {
  background-color: #f1f8ff;
  color: #586069;
}

.card {
  border: none;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card-header {
  border: 1px solid #c8e1ff;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

.card .card-body {
  border: 1px solid #e1e4e8;
  border-top: none;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.card-body .table th {
  border-top: none;
}

.pay-methods-card .list-group-item {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

table {
  font-size: 0.85rem;
}

.table td {
  padding: .4rem;
}

