.card-header {
  background-color: #f1f8ff;
  color: #586069;
}

.card {
  border: none;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card-header {
  border: 1px solid #c8e1ff;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

.card .card-body {
  border: 1px solid #e1e4e8;
  border-top: none;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.card-body .table th {
  border-top: none;
}

.pay-methods-card .list-group-item {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

table {
  font-size: 0.85rem;
}

.table td {
  padding: .4rem;
}
